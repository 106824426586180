import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Link,
} from "react-router-dom";

import Main from "./main";
import AboutUs from "./components/Rasoda/Footer/aboutUs";
import PrivacyPolicy from "./components/Rasoda/Footer/privacyPolicy";
import RefundPolicy from "./components/Rasoda/Footer/refundPolicy";
import ContentPolicy from "./components/Rasoda/Footer/contentPolicy";
import ShippingPolicy from "./components/Rasoda/Footer/shippingPolicy";
import TermsConditions from "./components/Rasoda/Footer/terms&Conditions";
import Navbar from "./components/Navbar/navbar";
import BecomeAnInvestor from "./investorMain";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import News from "./components/Rasoda/Footer/news";
import OurCulture from "./components/Rasoda/Footer/ourCulture";
import MobiePrivacyPolicy from "./androidPages/privacyPolicy";
import MobileTermsConditions from "./androidPages/term";
import DeepLinking from "./deepLinking/deepLinking";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Main />} exact />
        <Route path="/Aboutus" element={<AboutUs />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
        <Route path="/contentPolicy" element={<ContentPolicy />} />
        <Route path="/shippingPolicy" element={<ShippingPolicy />} />
        <Route path="/terms&conditions" element={<TermsConditions />} />
        <Route path="/home" element={<Main />} />
        <Route path="/contactUs" element={<Main />} />
        <Route path="/sellOnRasoda" element={<Main />} />
        <Route path="/investor" element={<BecomeAnInvestor />} />
        <Route path="/news" element={<News />} />
        <Route path="/ourculture" element={<OurCulture />} />
        <Route path="/mobile/privacyPolicy" element={<MobiePrivacyPolicy />} />
        <Route path="/mobile/terms" element={<MobileTermsConditions />} />
        <Route path="/store" element={<DeepLinking />} />
      </Routes>
    </Router>
  );
};

export default App;

