import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "../../App.css";

const Input = (props) => {
  const handleOnChange = (value, countryData) => {
    const { dialCode } = countryData;
    const extractedCountryCode = `+${dialCode}`;
    let extractedPhoneNumber = value;

    const startsWithCountryCode = value.startsWith(extractedCountryCode);

    if (startsWithCountryCode) {
      extractedPhoneNumber = `${extractedCountryCode}${value}`;
    } else {
      extractedPhoneNumber = value.substr(extractedCountryCode.length - 1);
    }
    props.setPhoneNumber(extractedPhoneNumber);
    props.setCountryCode(extractedCountryCode);
  };

  return (
    <div className="p-2 flex">
      <div className="w-full">
        <PhoneInput
          country={"in"}
          inputStyle={{
            height: "45px",
            width: "100%",
            borderRadius: "13px",
            border: "none",
            marginLeft: "10px",
          }}
          onChange={handleOnChange}
          inputClass="input"
          enableSearch
        />
        {props.touched && props.error && (
          <p className="text-red-500">{props.error}</p>
        )}
      </div>
    </div>
  );
};

const Index = (props) => {
  return (
    <Input
      label="Mobile Phone"
      req={true}
      helperText={""}
      error={true}
      isSelect={false}
      {...props.input}
      {...props.meta}
      {...props.custom}
      name={props.name}
      setCountryCode={props.setCountryCode}
      setPhoneNumber={props.setPhoneNumber}
    />
  );
};

export default Index;
